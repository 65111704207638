import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEng from "../locale/en.json";
import translationCze from "../locale/cs.json";

const DETECTION_OPTIONS = {
  order: ['navigator']
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    detection: DETECTION_OPTIONS,
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    resources: {
      en: {
        translations: translationEng
      },
      cs: {
        translations: translationCze
      }
    },
    ns: ["translations"],
    defaultNS: "translations"
});

export default i18n;